import {useEffect, useRef, useState} from 'react';
import {useLocation} from '@remix-run/react';

import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import TableOfContents from '@/pages/shopify.com/($locale)/legal/components/TableOfContents/TableOfContents';
import Markdown from '@/pages/shopify.com/($locale)/legal/components/Markdown/Markdown';

type HeadingVisibilityMap = Map<string, boolean>;

const getFirstVisibleId = (
  headingVisibilityMap: HeadingVisibilityMap,
  headings: NodeListOf<HTMLHeadingElement>,
) => {
  const visibleIds = getVisibleIds(headingVisibilityMap);
  const firstVisibleHeading = Array.from(headings).find((heading) =>
    visibleIds.includes(heading.id),
  );
  return firstVisibleHeading?.id ?? null;
};

const getVisibleIds = (headingVisibilityMap: HeadingVisibilityMap) => {
  const visibleIds: string[] = [];
  headingVisibilityMap.forEach((isVisible, id) => {
    if (isVisible) {
      visibleIds.push(id);
    }
  });
  return visibleIds;
};

const LegalArticle = ({parsedContentMd}: {parsedContentMd: string}) => {
  const markdownContainerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver>();
  const visibilityRef = useRef<HeadingVisibilityMap>(new Map());

  const {hash} = useLocation();

  const [activeId, setActiveId] = useState<string>(() => {
    if (hash) {
      return hash.slice(1);
    }
    return '';
  });

  useEffect(() => {
    if (!markdownContainerRef.current) {
      return;
    }

    // Find all the h2 elements in the rendered Markdown content
    const headings = markdownContainerRef.current.querySelectorAll('h2');

    const options = {
      root: null,
      threshold: 1,
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        visibilityRef.current.set(entry.target.id, entry.isIntersecting);
      });

      const firstVisibleId = getFirstVisibleId(visibilityRef.current, headings);
      // There can be a case where the first visible id is null, because the paragraph under the headings
      // are so large that no headings are visible. In this case, we want to keep the previous active id
      if (firstVisibleId) {
        setActiveId(firstVisibleId);
      }
    };

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(handleIntersection, options);

    headings.forEach((heading) => {
      observerRef.current?.observe(heading);
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [markdownContainerRef]);

  return (
    <Grid>
      <Col
        className="relative flex flex-col"
        start={{xs: 1, sm: 2, md: 3, lg: 1}}
        span={{xs: 4, sm: 6, md: 8, lg: 3}}
      >
        <TableOfContents contentMd={parsedContentMd} activeId={activeId} />
      </Col>
      <Col
        className="relative flex flex-col gap-y-xl"
        start={{xs: 1, sm: 2, md: 3, lg: 4}}
        span={{
          xs: 4,
          sm: 6,
          md: 8,
          lg: parsedContentMd.includes('<aside>') ? 6 : 7,
        }}
      >
        <Markdown ref={markdownContainerRef}>{parsedContentMd}</Markdown>
      </Col>
    </Grid>
  );
};

export default LegalArticle;
